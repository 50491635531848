<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div class="col-6">
        <!--        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Property</label>
          <div class="p-col">
            <v-select :clearable="false" title="Property" class="" @search="SearchProperty" placeholder="Choose" v-model="selProperty" :options="listProperty" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="property">
          </div>
        </div>-->

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Name of type</label>
          <div class="p-col">
            <InputText maxlength="200" id="name" type="text" class="" v-model="obj_data.name" placeholder="Input" />
          </div>
        </div>
<!--        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Order</label>
          <div class="p-col">
            <InputNumber v-model="obj_data.ordinal">
            </InputNumber>
          </div>
        </div>-->
        <div class="p-card-field div_check">
          <div class="p-col checkbox">
            <Checkbox v-model="obj_data.active" :binary="true" />
            <label class="p-col-fixed txt-right text">Active</label>
          </div>
        </div>

        <!--        <div class="p-card-field">
          <label class="p-col-fixed txt-right" :class="`${mode=='add' ? 'txt-required' : ''}`">From - to</label>
          <div class="p-col">
            <Calendar 
              :showOnFocus="false" 
              dateFormat="MM dd, yy" 
              class=""
              :showIcon="true" 
              selectionMode="range"
              appendTo="body"
              :showClear="true" 
              :manualInput="false"
              v-model="from_to" :disabled="mode=='edit'" placeholder="Choose"
            />
            <input class="input_tmp_validator" id="from_to"/>
          </div>
        </div>-->
        <!--        <div class="p-card-field">
          <div class="row">
            <div class="col-6">
              <label class="p-col-fixed txt-right txt-required">Start booking</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  v-model="start_booking_date" placeholder="Choose"
                />
                <input class="input_tmp_validator" id="start_booking_date"/>
              </div>
            </div>
              
            <div class="col-6">
              <label class="p-col-fixed txt-right">End</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy" 
                  id="end_booking_date"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  :disabled="true"
                  v-model="end_booking_date"
                  placeholder="Choose"
                />
              </div>
            </div>
          </div>
        </div>-->
        <!--        <div class="p-card-field">
          <h3>ORDER OF BOOKING ({{partArr.length}}/5)</h3>
        </div>
        <packageDescriptions :key="componentKey" v-model="partArr" :mode="mode"/>
        
        <matrixComponent @updateMatrix="updateMatrix" v-model="partArr" :dataBook="dataBookingRound" :show="true" :property="selProperty" :key="`matrix-${componentKey}`"/>
        
        <div v-if="mode == 'edit'" class="p-card-field">
          <a @click="toCalendar" class="txt-link pl-3"><i>View on calendar</i></a>
        </div>-->
      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button :label="$constants.TEXT_BUTTON.BACK" @click="backToList()" class="p-button-outlined" id="back" />
          <Button v-if="mode == 'edit'" :label="$constants.TEXT_BUTTON.EDIT" @click="saveData()"
            class="main-style-button" />
          <Button v-else :label="$constants.TEXT_BUTTON.ADD" @click="saveData()" class="main-style-button" />
        </template>
      </Toolbar>
    </div>
  </form>
</template>
<script>
import DataServices from '@/core/DataServices';
import moment from "moment-timezone";
export default {
  components: {

  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      obj_data: {

      },
      dataBookingRound: [],
      selProperty: null,
      listProperty: [],
      listPropertyAll: [],
      from_to: null,
      address: null,
      start_booking_date: null,
      end_booking_date: null,
      partArr: [],
      time_zone_local: moment.tz.guess(true),
      componentKey: 0
    }
  },
  watch: {
    /* async "selProperty"() {
       if (!this.$commonFuction.isEmptyObject(this.selProperty)) {
         this.address = this.selProperty?.address;
         this.partArr = this.selProperty?.part;
         if (this.mode == 'add') {
           var arr = [];
           this.partArr.map(m => {
             arr.push({ord: m.order_booking, id: m.id});
           })
           arr.map(m=> {
             var tmp = [];
             for (let i =0; i < arr.length; i++) {
               let a = m.ord - i + arr.length;
               if (a > arr.length) {
                 a = a - arr.length;
               }
               tmp.push({ordinal: a, part_id: m.id, round: (i+1)});
             }
             this.dataBookingRound.push(tmp);
           })
         }
         this.componentKey++;
       }
     },
     "start_booking_date"() {
       if (this.partArr.length) {
         let days = 14 * this.partArr.length;
         this.end_booking_date = moment.tz(this.start_booking_date, this.time_zone_local).add(days, 'days').format("LL");
       }
     }*/
  },
  async mounted() {
    //await this.getListProperty();
    console.log("this.model:", this.model)
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      this.obj_data = this.model;
      this.componentKey++;
    } else {
      //thêm mới
      this.obj_data = {
        active: true,
        ordinal: 1,
      };
      this.componentKey++;
    }
  },
  methods: {
    updateMatrix(data) {
      console.log(data);
      this.dataBookingRound = [...data];
      // this.componentKey++;
    },
    async toCalendar() {
      this.$router.push(`/admin/list-booking?view=calendar&propery_id=${this.selProperty?.id}`);
    },
    async getListProperty() {

      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      let { data } = await this.$apollo.query({
        query: DataServices.getList('property', { "fields": `id code name address booking{id} part {id part order_booking user {id fullname email}}` }),
        variables: {
          where_key: where_key,
          orderBy: [{ created_at: 'desc' }]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_property = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
          list_property.push({ id: e.id, name: e.name, code: e.code, address: e.address, part: e.part });
        }
      }
      this.listProperty = list_property;
      this.listPropertyAll = list_property;
    },

    async SearchProperty(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
          // booking: {id: {_is_null: true} },
          _or: {
            _iregex: this.$commonFuction.search(search),
          }
        };
        let { data } = await this.$apollo.query({
          query: DataServices.getList('property', { "fields": `id code name address booking {id} part {id part order_booking user {id fullname email}}` }),
          variables: {
            where_key: where_key,
            orderBy: [{ created_at: 'desc' }]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_property = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          if (!e?.booking?.id || (this.selProperty?.id && this.selProperty?.id == e.id)) {
            list_property.push({ id: e.id, name: e.name, code: e.code, address: e.address, part: e.part });
          }
        }
        this.listProperty = list_property;
      } else {
        // cosnole.log("dasdasd")
        this.listProperty = this.listPropertyAll
      }
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};


      let name_add = document.getElementById("name");
      if (this.$commonFuction.isEmptyObject(this.obj_data.name)) {
        name_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        name_add.setCustomValidity("");
      }



      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },

    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      console.log("this.from_to", this.from_to);
      console.log("partArr", this.dataBookingRound);
      var params = {
        name: this.obj_data?.name ?? '',
        ordinal: this.obj_data?.ordinal ?? 1,
        active: this.obj_data?.active ?? true,
      }
      console.log('saveData', params)
      this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}

.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}

.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.p-multiselect-label-container {
  height: 40px;
}

.p-inputtext {
  margin: 0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}

label.p-col-fixed txt-right {
  margin-bottom: 0px !important;
}

div.p-card-field {
  padding: 0px !important;
}

.p-col-fixed {
  padding: 0px !important;
  margin: 0px 0px 0px 9px !important;
}

.input_text {
  width: 760px !important;
  height: 48px !important;
}

.txt-right {
  font-weight: bold;
}

.multiselect {
  width: 760px !important;
}

.layout-main {
  background: #00000029;
}

.vs__clear {
  display: none;
}

.input_width {
  width: 760px !important;
  height: 48px !important;
}

#distance {
  width: 1px !important;
  height: 48px !important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}

.manager-title {
  font-size: 24px !important;
}

.input-dif {
  margin-left: 5px !important;
  border-top: 0px;
}

.p-inputgroup-addon {
  height: 48px;
}

.txt-right {
  font-weight: bold;
}

.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}

label.p-col-fixed txt-right {
  margin-bottom: 0px !important;
}

div.p-card-field {
  padding: 0px !important;
}

.margin {
  margin-bottom: 12px !important;
}

.border_color {
  border: 1px solid #C8C8C8;
}

.text {
  font-weight: normal !important;
  margin-left: 15px !important;
}

.div_check {
  margin-top: 12px !important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width {
    width: 100% !important;
  }

  .div-button {
    padding: 8px !important;
  }

  #distance {
    text-align: left !important;
    padding: 6.8px;
    width: 100% !important;
  }

  .p-inputgroup-addon {
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width {
    width: 100% !important;
  }

  .div-button {
    padding: 8px !important;
  }

  .p-inputgroup-addon {
    margin-right: 12px;
  }

  #distance {
    text-align: left !important;
    padding: 6.8px;
  }
}

.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}
</style>
